import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { PAYMENT_METHOD_QUERY } from '@/gql/queries';
import { useTheme } from 'styled-components';
import { DefaultButton } from '@getgrover/ui';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';

import { tk } from '@/i18n/translationKeys';
import { TrackYourPaymentsEvents } from '@/analytics';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import { useApplicationData } from '@/providers/applicationData';
import { Wrapper } from '@/components/Wrapper';
import { Group } from '@/components/Group';
import { Text } from '@/components/Text';
import { InvalidIconOutlined } from '@/icons/InvalidIconOutlined';
import { SubscriptionPayment } from '@/generated/types';

import { ExternalLink } from '../YourPaymentsPage.styles';
import { Root, ViewDetailsButton } from './FailedBanner.styles';

interface FailedBannerProps {
  totalCount: number;
  paymentsList: SubscriptionPayment[];
}

export const FailedBanner = ({
  totalCount,
  paymentsList
}: FailedBannerProps) => {
  const { t } = useTranslation();
  const { userId, storeCode, routeBuilder } = useApplicationData();
  const { isMobile } = useScreenMatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { isMultipleContracts, isMultiplePaymentMethods, singleContractName, singleContractId, singlePaymentMethodId } = useMemo(() => {
    const hasMultiple = (data: SubscriptionPayment[], key: string): boolean => {
      const seen = new Set();

      for (const item of data) {
        let value: string | undefined;

        switch (key) {
          case 'paymentMethod':
            value = item.contract?.paymentMethod?.id;
            break;
          case 'contract':
            value = item.contract?.id;
            break;
        }

        if (value) {
          seen.add(value);
          if (seen.size > 1) return true;
        }
      }

      return false;
    };

    return {
      isMultipleContracts: hasMultiple(paymentsList, 'contract'),
      isMultiplePaymentMethods: hasMultiple(paymentsList, 'paymentMethod'),
      singleContractName: paymentsList[0]?.contract?.__typename === 'Flex' ? paymentsList[0]?.contract?.name : '',
      singleContractId: paymentsList[0]?.contract?.id ?? '',
      singlePaymentMethodId: paymentsList[0]?.contract?.paymentMethod?.id ?? ''
    };
  }, [paymentsList]);

  const { data } = useQuery(PAYMENT_METHOD_QUERY, {
    variables: { id: singlePaymentMethodId },
    skip: isMultiplePaymentMethods,
    fetchPolicy: 'network-only',
  });

  const handleViewDetailsClick = () => {
    TrackYourPaymentsEvents.failedBannerViewDetailsClick({
      userId,
      store: storeCode,
      isMultipleContracts,
      isMultiplePaymentMethods,
    });

    if (isMultipleContracts) {
      if (isMultiplePaymentMethods) {
        return location.href = routeBuilder.buildSubscriptionDetailsRoute();
      }

      return navigate(routeBuilder.buildPaymentMethodDetailsRoute({
        billingAccountId: data?.paymentMethod?.id ?? '',
        query: { retry: true }
      }));
    }
    return location.href = routeBuilder.buildSubscriptionDetailsRoute({ subscriptionId: singleContractId });
  };

  const handleChangeClick = () => {
    TrackYourPaymentsEvents.failedBannerChangePaymentMethodClick({
      userId,
      store: storeCode,
      isMultipleContracts,
      isMultiplePaymentMethods,
    });

    if (isMultiplePaymentMethods || !data?.paymentMethod?.id) {
      return navigate(routeBuilder.buildPaymentMethodListRoute({ query: { retry: true } }));
    }

    if (isMultipleContracts) {
      return navigate(
        routeBuilder.buildPaymentMethodBulkUpdateRoute({
          paymentMethodId: data?.paymentMethod?.id,
          query: { retry: true },
        })
      );
    }

    navigate(
      routeBuilder.buildChangePaymentMethodRoute({
        subscriptionId: singleContractId,
        query: { retry: true },
      })
    );
  };

  const renderButtonGroup = () => {
    return (
      <Wrapper margin={isMobile ? '24px 0 0' : ''}>
        <Group gap={4} mobileGap={4} fullWidth={isMobile}>
          <ViewDetailsButton
            size="S"
            variant="Primary"
            fullWidth={isMobile}
            onClick={handleViewDetailsClick}
          >
            {t(isMobile ? tk.yourPaymentsViewDetailsButtonMobileText : tk.yourPaymentsViewDetailsButtonText)}
          </ViewDetailsButton>
          <DefaultButton
            size="S"
            variant="Primary"
            fullWidth={isMobile}
            onClick={handleChangeClick}
          >
            {isMobile ? t(tk.paymentMethodBulkUpdateButtonText) : t(tk.bulkUpdateChangePaymentMethodButtonText)}
          </DefaultButton>
        </Group>
      </Wrapper>
    );
  };

  return (
    <Root isMobile={isMobile}>
      <Group gap={1} mobileGap={1} centered>
        <InvalidIconOutlined size="20" />
        <Text typography='SmallCapsHeadline' color={theme.colors.status.baseRed}>{t(tk.yourPaymentsFailedBannerTitle)}</Text>
      </Group>
      <Wrapper justifyContent='space-between' margin={isMobile ? '8px 0 12px' : '8px 0'}>
        <Wrapper maxWidth='600px'>
          <Text typography='Paragraph' color={theme.colors.text.darkerGrey}>{t(tk.yourPaymentsFailedBannerSubtitle, { totalCount })}&nbsp;
            {isMultipleContracts || !singleContractName ? t(tk.yourPaymentsFailedBannerYourRentals) : <b>{singleContractName}</b>}.
          </Text>
        </Wrapper>
        {isMobile ? <></> : renderButtonGroup()}
      </Wrapper>
      <Text typography='TinyParagraph' color={theme.colors.text.darkerGrey}>
        <Markdown
          options={{
            overrides: {
              a: {
                component: ExternalLink,
              },
            },
          }}
        >
          {t(tk.yourPaymentsFailedBannerRetryLearnMore)}
        </Markdown>
      </Text>
      {isMobile ? renderButtonGroup() : null}
    </Root >
  );
};
