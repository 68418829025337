import styled from 'styled-components';
import { Group } from '@/components/Group';

export const Root = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const HeaderWrapper = styled.div`
  height: 64px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
`;

export const ContentGroup = styled(Group)`
  overflow-y: auto;
  height: calc(100% - 186px);
  width: 100%;
  padding: 24px 16px;
  position: fixed;
  top: 64px;
  left: 0;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 34px;
  left: 0;
  padding: 0 24px;
`;
