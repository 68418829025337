import { PaymentState, PaymentsUnion } from '@/generated/types';
import { GroupedPayments, TFn, UiFriendlyPayment, PaymentGroupType } from '../types';
import { getSvgPath } from './helpers/getSvgPath';
import { getTitle } from './helpers/getTitle';
import { getSubtitle } from './helpers/getSubtitle';
import { getFormattedPrices } from './helpers/getFormattedPrices';
import { getLinkToSubscription } from './helpers/getLinkToSubscription';
import { getPaidDate } from './helpers/getPaidDate';

const addGroup = ({
  groups,
  payment,
  name,
}: {
  groups: Map<string, GroupedPayments>;
  payment: UiFriendlyPayment;
  name: string;
}): void => {
  const existingGroup = groups.get(name);

  if (existingGroup) {
    existingGroup.payments.push(payment);
  } else {
    groups.set(name, { payments: [payment], name });
  }
};

const mapPaymentToUiFriendly = (payment: PaymentsUnion, t: TFn): UiFriendlyPayment => {
  const svgPath = getSvgPath(payment);
  const title = getTitle(payment, t);
  const subtitle = getSubtitle(payment, t);
  const { strikethroughPrice, actualPrice, refundedAmount } = getFormattedPrices(payment);
  const linkToSubscription = getLinkToSubscription(payment, 'de', t);
  const paidDate = getPaidDate(payment, t);

  return {
    id: payment.id,
    invoiceUrl: payment.invoiceUrl ?? '',
    title,
    subtitle,
    svgPath,
    state: payment.state,
    strikethroughPrice,
    actualPrice,
    refundedAmount,
    linkToSubscription,
    paidDate,
    typename: payment.__typename ?? '',
  };
};

export const mapPaymentsQueryResponseToGroupedPayments = (
  payments: PaymentsUnion[],
  t: TFn
): GroupedPayments[] => {
  const groups = new Map(); // Map because we've to keep order

  payments.forEach((payment) => {
    if (!payment) {
      return;
    }

    if (payment.state === PaymentState.DUE) {
      return addGroup({
        groups,
        name: PaymentGroupType.AwaitingPayment,
        payment: mapPaymentToUiFriendly(payment, t),
      });
    }

    let date;
    if (payment.dueDate?.__typename === 'Date') {
      date = new Date(payment.dueDate.iso8601);
    } else if ('datePaid' in payment && !!payment.datePaid) {
      date = new Date(payment.datePaid);
    }

    const failedOrderPayment =
      payment.__typename === 'OrderPayment' && payment.state === PaymentState.FAILED;

    // If there was no date set, try falling back to time estimation.
    if (
      payment.state === PaymentState.SCHEDULED ||
      (!date && payment.dueDate?.__typename === 'TimeEstimate' && !failedOrderPayment)
    ) {
      return addGroup({
        groups,
        name: PaymentGroupType.ComingUp,
        payment: mapPaymentToUiFriendly(payment, t),
      });
    }

    if (!date && !failedOrderPayment) {
      throw new Error('It is not failed order payment and impossible to parse date');
    }

    return addGroup({
      groups,
      name: PaymentGroupType.Others,
      payment: mapPaymentToUiFriendly(payment, t),
    });
  });

  return Array.from(groups.entries())
    .sort(([a], [b]) =>
      a === PaymentGroupType.ComingUp
        ? -1
        : b === PaymentGroupType.ComingUp
        ? 1
        : -a.localeCompare(b)
    )
    .map(([_k, v]) => v);
};
