import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Link } from '@/components/Link';
import { useError } from '@/hooks/useError';
import { usePaymentMethods } from './usePaymentMethods';
import { PaymentMethodListView } from '@/components/PaymentMethod/PaymentMethodListView';
import { LoadingUnit } from '@/components/Loading';
import { BULK_UPDATE_LOCAL_STORAGE_KEY_PREFIX } from '@/pages/ChangePaymentMethodPage/constants';
import { getItemWithExpiry } from '@/utils/localStorage';
import { tk } from '@/i18n/translationKeys';
import { trackPaymentMethodListPageItemClickEvent } from '@/analytics';
import { useApplicationData } from '@/providers/applicationData';
import { useTheme } from 'styled-components';
import { QueryParamName } from '@/constants';

const isBeingDeleted = (paymentMethodId: string): boolean => {
  const localStorageKey = `${BULK_UPDATE_LOCAL_STORAGE_KEY_PREFIX}${paymentMethodId}`;
  const localStorageData = getItemWithExpiry(localStorageKey);
  return localStorageData?.deleteMode;
};

export const PaymentMethodList = () => {
  const [searchParams] = useSearchParams();
  const { userId, storeCode, routeBuilder } = useApplicationData();
  const { t } = useTranslation();
  const theme = useTheme();
  const throwError = useError();
  const { otherPaymentMethods, cards, loading, error } = usePaymentMethods();
  const isRetryFlow = !!searchParams.get(QueryParamName.retry);

  useEffect(() => {
    if (error) {
      throwError(error);
    }
  }, [error, throwError]);

  if (loading) {
    return (
      <Group vertical gap={4} mobileGap={4}>
        <LoadingUnit key={1} height={5} width={16} />
        <LoadingUnit key={2} height={27} />
      </Group>
    );
  }

  const sendLinkClickAnalyticsEvent = () => {
    trackPaymentMethodListPageItemClickEvent({
      userId,
      store: storeCode,
    });
  };

  const cardList = cards.map((card) => {
    const beingDeleted = isBeingDeleted(card.id);

    return (
      <Link
        key={card.id as string}
        to={routeBuilder.buildPaymentMethodDetailsRoute(isRetryFlow ? { billingAccountId: String(card.id), query: { retry: true } } : { billingAccountId: String(card.id) })}
        onClick={sendLinkClickAnalyticsEvent}
      >
        <PaymentMethodListView paymentMethod={card} beingDeleted={beingDeleted} />
      </Link>
    );
  });

  const otherPaymentMethodsList = otherPaymentMethods.map((otherPm) => {
    const beingDeleted = isBeingDeleted(otherPm.id);

    return (
      <Link
        key={otherPm.id as string}
        to={routeBuilder.buildPaymentMethodDetailsRoute(isRetryFlow ? { billingAccountId: String(otherPm.id), query: { retry: true } } : { billingAccountId: String(otherPm.id) })}
        onClick={sendLinkClickAnalyticsEvent}
      >
        <PaymentMethodListView paymentMethod={otherPm} beingDeleted={beingDeleted} />
      </Link>
    );
  });

  return (
    <Group vertical gap={8} mobileGap={4}>
      {cards.length ? (
        <Group vertical gap={4} mobileGap={4} data-testid="payment-method-list-group">
          <Text
            typography="SuperTinyHeadline"
            color={theme.colors.text.darkerGrey}
            data-testid="payment-method-list-group-title"
          >
            {t(tk.paymentMethodListPageCardsSubtitle)}
          </Text>
          <Group vertical gap={4} mobileGap={4}>
            {cardList}
          </Group>
        </Group>
      ) : null}

      {otherPaymentMethodsList.length ? (
        <Group vertical gap={4} mobileGap={4} data-testid="payment-method-list-group">
          <Text
            typography="SuperTinyHeadline"
            color={theme.colors.text.darkerGrey}
            data-testid="payment-method-list-group-title"
          >
            {t(tk.paymentMethodListPageOthersSubtitle)}
          </Text>
          <Group vertical gap={4} mobileGap={4}>
            {otherPaymentMethodsList}
          </Group>
        </Group>
      ) : null}
    </Group>
  );
};
