import React from 'react';

import { Group } from '@/components/Group';
import { LoadingUnit } from '@/components/Loading';
import { Wrapper } from '@/components/Wrapper';
import { useScreenMatch } from '@/hooks/useScreenMatch';

import { Root } from './FailedBanner.styles';

export const FailedBannerPreloader = () => {
  const { isMobile } = useScreenMatch();

  const renderButtonGroup = () => {
    return (
      <Wrapper margin={isMobile ? '24px 0 0' : ''}>
        <Group gap={4} mobileGap={4} fullWidth={isMobile}>
          <LoadingUnit height={8} width={32} />
          <LoadingUnit height={8} width={57} />
        </Group>
      </Wrapper>
    );
  };

  return (
    <Root isMobile={isMobile} isLoading>
      <LoadingUnit height={isMobile ? 12 : 6} width={37} />
      <Wrapper justifyContent='space-between' margin={isMobile ? '8px 0 12px' : '8px 0'}>
        <LoadingUnit height={8} width={100} />
        {isMobile ? <></> : renderButtonGroup()}
      </Wrapper>

      <LoadingUnit height={isMobile ? 10 : 5} width={isMobile ? 50 : 100} />
      {isMobile ? renderButtonGroup() : null}
    </Root >
  );
};
