import { sendAnalyticsEvent } from '@devsbb/analytics-client';

type EventProperties = {
  userId: string;
  store: string;
  subscriptionId?: string;
  filterType?: string;
  filterOption?: string;
  applyButtonName?: string;
  isMultipleContracts?: boolean;
  isMultiplePaymentMethods?: boolean;
};

export class TrackYourPaymentsEvents {
  static paymentItemClick(properties: EventProperties) {
    sendAnalyticsEvent<EventProperties>('Payments Item Clicked', properties);
  }

  static invoiceDownloadClick(properties: EventProperties) {
    sendAnalyticsEvent<EventProperties>('Download Invoice Button Clicked', properties);
  }

  static subscriptionLinkClick(properties: EventProperties) {
    sendAnalyticsEvent<EventProperties>('Subscription Link Clicked', properties);
  }

  static paymentMethodsLinkClick(properties: EventProperties) {
    sendAnalyticsEvent<EventProperties>('Payment Methods Link Clicked', properties);
  }

  static filterChipClick(properties: EventProperties) {
    sendAnalyticsEvent<EventProperties>('Filter Chip Clicked', properties);
  }

  static filterTypeClick(properties: EventProperties) {
    sendAnalyticsEvent<EventProperties>('Filter Type Clicked', properties);
  }

  static filterOptionClick(properties: EventProperties) {
    sendAnalyticsEvent<EventProperties>('Filter Option Clicked', properties);
  }

  static filterApplyClick(properties: EventProperties) {
    sendAnalyticsEvent<EventProperties>('Filter Apply Button Clicked', properties);
  }

  static filterClearAllClick(properties: EventProperties) {
    sendAnalyticsEvent<EventProperties>('Filter Clear All Button Clicked', properties);
  }

  static failedBannerViewDetailsClick(properties: EventProperties) {
    sendAnalyticsEvent<EventProperties>('Failed Banner View Details Button Clicked', properties);
  }

  static failedBannerChangePaymentMethodClick(properties: EventProperties) {
    sendAnalyticsEvent<EventProperties>('Failed Banner Change Payment Method Button Clicked', properties);
  }
}
