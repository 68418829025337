import { PaymentState, PaymentsUnion, TimeUnit } from '@/generated/types';
import { TFn } from '@/pages/YourPaymentsPage/types';
import { formatDate } from '@/utils/date';
import { getLocale } from '@/utils/cookies';
import { tk } from '@/i18n/translationKeys';

const translationKeymap: { [keys in TimeUnit]: string } = {
  [TimeUnit.YEARS]: tk.yourPaymentsOrderTimeEstimationYear,
  [TimeUnit.MONTHS]: tk.yourPaymentsOrderTimeEstimationMonth,
  [TimeUnit.DAYS]: tk.yourPaymentsOrderTimeEstimationDay,
  [TimeUnit.HOURS]: tk.yourPaymentsOrderTimeEstimationHour,
  [TimeUnit.MINUTES]: tk.yourPaymentsOrderTimeEstimationMinute,
};

function getFormattedDate(payment: PaymentsUnion, t: TFn, mobile?: boolean): string {
  const locale = getLocale();

  if (payment.__typename === 'PurchasePayment') {
    const date = payment.datePaid ?? payment.dueDate;
    return formatDate({ date, dateFormat: 'MMM DD, YYYY', locale });
  }

  const date = payment.dueDate;

  if (date.__typename === 'TimeEstimate') {
    const { atLeast, atMost } = date;
    const { value: atLeastValue, unit: atLeastUnit } = atLeast;

    const atLeastUnitTk = t(`${translationKeymap[atLeastUnit]}`, {
      count: atLeastValue,
    });

    if (!atMost) {
      return t(tk.yourPaymentsInAbout, { value: `${atLeastValue} ${atLeastUnitTk}` });
    }

    const { value: atMostValue, unit: atMostUnit } = atMost;
    const atMostUnitTk = t(`${translationKeymap[atMostUnit]}`, {
      count: atMostValue,
    });

    if (atLeastUnit === atMostUnit) {
      return `${atLeastValue}-${atMostValue} ${atMostUnitTk}`;
    }

    return `${atLeastValue} ${atLeastUnitTk} - ${atMostValue} ${atMostUnitTk}`;
  }

  if (date.__typename === 'Date') {
    return formatDate({ date: date.iso8601, dateFormat: 'MMM DD, YYYY', locale, mobile });
  }

  throw new Error(`Parameter "date" of type ${JSON.stringify(date)} is not supported`);
}

const paymentTypeMap = {
  OrderPayment: tk.yourPaymentsTypeOrderText,
  SubscriptionPayment: tk.yourPaymentsTypeSubscriptionText,
  PurchasePayment: tk.yourPaymentsTypePurchaseText,
  GroupPayment: tk.yourPaymentsTypeGroupText,
};

export const getSubtitle = (payment: PaymentsUnion, t: TFn, mobile?: boolean): string => {
  // Failed order payments shouldn't render the due date estimate since they won't be retried.
  // Although this is a business logic and shouldn't be in the UI, it was shown really problematic to
  // change the GraphQL API and its clients to accommodate it.
  if (payment.__typename === 'OrderPayment' && payment.state === PaymentState.FAILED) {
    return t(paymentTypeMap['OrderPayment']);
  }

  const formattedDate = getFormattedDate(payment, t, mobile);
  return `${payment.__typename && t(paymentTypeMap[payment.__typename])} · ${t(
    tk.nextPaymentDueText
  )} ${formattedDate}`;
};
