import { PaymentsUnion } from '@/generated/types';
import { formatPriceByLanguage } from '@/utils/money';
import { CURRENCIES_ASSOCIATION } from '@/constants';
import { getLocale } from '@/utils/cookies';

interface GetFormattedPricesReturn {
  strikethroughPrice: string | null;
  actualPrice: string;
  refundedAmount: string | null;
}

export const getFormattedPrices = (payment: PaymentsUnion): GetFormattedPricesReturn => {
  const locale = getLocale();
  const currency = payment.basePrice.currency;
  const currencySymbol = CURRENCIES_ASSOCIATION[currency];

  let strikethroughPrice = null;
  if (payment.price.inCents < payment.basePrice.inCents) {
    strikethroughPrice = formatPriceByLanguage(payment.basePrice.inCents, locale, currencySymbol);
  }

  const actualPrice = formatPriceByLanguage(payment.price.inCents, locale, currencySymbol);

  let refundedAmount = null;
  if (payment.refundedAmount.inCents > 0) {
    refundedAmount = formatPriceByLanguage(payment.refundedAmount.inCents, locale, currencySymbol);
  }

  return {
    strikethroughPrice,
    actualPrice,
    refundedAmount,
  };
};
