export enum FeatureFlagName {
  NEW_CARD_FORM = 'pmmp_new_card_form',
  FILTER_YOUR_PAYMENTS = 'pmmp_filter_your_payments',
  YOUR_PAYMENTS_DISPLAY_REFUNDED = 'pmmp_your_payments_display_Refunded',
}

export const flagKeys: FeatureFlagName[] = [
  FeatureFlagName.NEW_CARD_FORM,
  FeatureFlagName.FILTER_YOUR_PAYMENTS,
  FeatureFlagName.YOUR_PAYMENTS_DISPLAY_REFUNDED,
];

export const defaultFlagData = flagKeys.reduce((acc, item) => {
  return {
    ...acc,
    [item]: false,
  };
}, {});
