import React, { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Offset } from '@/components/Offset';
import { Container } from '@/components/Container';
import { EmptyContentWrapper, ProgressHolder } from './LinkedSubscriptions.styles';
import { PaymentMethodWithContracts } from '@/types/paymentMethods';
import { ProgressIcon } from '@/icons/ProgressIcon';
import { tk } from '@/i18n/translationKeys';
import { ButtonV2 } from '@getgrover/ui';
import { RefreshIcon } from '@/icons/RefreshIcon';
import { EmptyIcon } from '@/icons/EmptyIcon';
import { trackPaymentMethodDetailsBulkUpdateLinkClickEvent } from '@/analytics';
import { useApplicationData } from '@/providers/applicationData';
import { FlexDetails, PaymentMethodStatus } from '@/generated/types';
import { QueryParamName } from '@/constants';
import { SubscriptionItem } from '@/components/SubscriptionItem';
import { useTheme } from 'styled-components';

interface LinkedSubscriptionsProps {
  paymentMethod: PaymentMethodWithContracts;
  inProgress: boolean;
}

export const LinkedSubscriptions = ({ paymentMethod, inProgress }: LinkedSubscriptionsProps) => {
  const { t } = useTranslation();
  const { userId, storeCode, routeBuilder } = useApplicationData();
  const [searchParams] = useSearchParams();
  const queryParamFrom = searchParams.get(QueryParamName.from);
  const isRetryFlow = !!searchParams.get(QueryParamName.retry);
  const navigate = useNavigate();
  const linkedContracts = paymentMethod.linkedContracts as FlexDetails[];
  const invalid = paymentMethod?.status === PaymentMethodStatus.INVALID;
  const theme = useTheme();

  const handleChangePaymentMethodButtonClick = useCallback(() => {
    trackPaymentMethodDetailsBulkUpdateLinkClickEvent({
      userId,
      store: storeCode,
      invalid,
      paymentMethodType: paymentMethod.__typename,
      queryParamFrom,
    });
    const url = routeBuilder.buildPaymentMethodBulkUpdateRoute(isRetryFlow ? {
      paymentMethodId: paymentMethod.id, query: { retry: true }
    } : {
      paymentMethodId: paymentMethod.id,
    });
    navigate(url);
  }, [storeCode, paymentMethod, navigate]);

  if (!linkedContracts.length) {
    return (
      <Container fullWidth>
        <EmptyContentWrapper>
          <Group centered vertical gap={4} mobileGap={4}>
            <EmptyIcon />

            <Text typography="Paragraph" color={theme.colors.text.baseGrey}>
              {t(tk.deletePaymentMethodLinkedSubscriptionsEmptyText)}
            </Text>
          </Group>
        </EmptyContentWrapper>
      </Container>
    );
  }

  const title = `${t(tk.deletePaymentMethodLinkedSubscriptionsTitle)} (${linkedContracts.length})`;

  return (
    <Container fullWidth title={title} testId="linked-subscriptions-block">
      <Group vertical gap={1} mobileGap={1}>
        {linkedContracts.map((contract) => {
          return <SubscriptionItem key={contract.id} contract={contract} />;
        })}
      </Group>

      <Offset top={6}>
        <ButtonV2 fullWidth icon={<RefreshIcon />} onClick={handleChangePaymentMethodButtonClick}>
          {t(tk.bulkUpdateChangePaymentMethodButtonText)}
        </ButtonV2>
      </Offset>

      {inProgress ? (
        <ProgressHolder data-testid="progress-holder">
          <Group centered vertical gap={3} mobileGap={3}>
            <ProgressIcon />
            <Text typography="SmallCapsHeadline" color={theme.colors.text.darkGrey}>
              {t(tk.paymentMethodInBulkUpdateText)}
            </Text>
          </Group>
        </ProgressHolder>
      ) : null}
    </Container>
  );
};
