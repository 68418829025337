import { TFunction } from 'i18next';
import { ContractState, FlexDetails, PaymentState } from '@/generated/types';

export enum TotalPaymentsType {
  Total = 'Total',
  Subscription = 'Subscription',
}

export type TFn = TFunction<'translation', undefined>;

export type QueryResult<T> = {
  error: boolean;
  loading: boolean;
  data: T | null;
};

export type PaymentsDetailsType = {
  error: boolean;
  loading: boolean;
  formattedPrice: string | null;
  subscriptionTitle: string | null;
};

export enum PaymentGroupType {
  AwaitingPayment = 'AwaitingPayment',
  ComingUp = 'ComingUp',
  Others = 'Others',
}

export interface GroupedPayments {
  name: string;
  payments: UiFriendlyPayment[];
}

export interface UiFriendlyPayment {
  id: string;
  svgPath: string;
  title: string;
  subtitle: string;
  state: PaymentState;
  strikethroughPrice: string | null;
  actualPrice: string;
  refundedAmount: string | null;
  invoiceUrl: string;
  paidDate: string;
  typename: string;

  linkToSubscription: {
    url: string;
    title: string;
    description?: string;
  };
}

export enum TYPE_KEYMAP {
  NextPaymentNote = 'nextPayment',
  ProcessingNote = 'processing',
  PausedNote = 'paused',
  EndedNote = 'ended',
  AttentionNote = 'attention',
  GenericText = 'text',
  GenericTextNote = 'text',
}

export enum NoteType {
  NextPaymentNote = 'NextPaymentNote',
  ProcessingNote = 'ProcessingNote',
  PausedNote = 'PausedNote',
  EndedNote = 'EndedNote',
  AttentionNote = 'AttentionNote',
  GenericText = 'GenericText',
  GenericTextNote = 'GenericTextNote',
}

export interface NoteData {
  type: TYPE_KEYMAP;
  data: any; // doesn't make sense to type that random type
  color?: string;
  nextPaymentText?: string;
}

export interface SubscriptionData {
  title: string;
  imageUrl: string;
  noteData: NoteData;
}

export interface QueryMapResult {
  subscriptionData: SubscriptionData | null;
  formattedPrice: string;
  totalText: string;
  durationText: string;
}

export type SubscriptionDetails = FlexDetails & {
  contractName: string;
  contractState: ContractState;
};

export type FilterTagOption = {
  label: string;
  value: string;
};

export enum FilterType {
  Device = 'device',
  Status = 'status',
  PaymentType = 'paymentType',
}
