import styled from 'styled-components';
import { DefaultButton } from '@getgrover/ui';

export const Root = styled.div<{ isMobile: boolean }>`
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: ${({ isMobile }) => (isMobile ? '720px' : '1080px')};
`;

export const BackButton = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;

export const FilterButton = styled(DefaultButton)`
  cursor: pointer;
  padding: 4px 4px 4px 8px;
  background-color: ${({ theme }) => theme.colors.background.superLightGrey};
  color: ${({ theme }) => theme.colors.text.darkerGrey};
  ${({ theme }) => theme.typography['RegularParagraph']};

  :hover {
    background-color: ${({ theme }) => theme.colors.hover.lighterGrey};
  }
`;

export const ExternalLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
`;
