import { gql } from '@apollo/client';

const PAYMENTS_FRAGMENT = gql`
  fragment PaymentsFragment on PaymentsUnionConnection {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    nodes {
      ... on OrderPayment {
        id
        state
        byInvoice
        invoiceNumber
        invoiceUrl
        price {
          currency
          inCents
        }
        basePrice {
          currency
          inCents
        }
        refundedAmount {
          currency
          inCents
        }
        dueDate {
          ... on Date {
            iso8601
          }
          ... on TimeEstimate {
            atLeast {
              unit
              value
            }
            atMost {
              unit
              value
            }
          }
        }
        paidDate
        order {
          ... on Order {
            number
            completedAt
            discountAmount {
              currency
              inCents
            }
            shippingPrice {
              currency
              inCents
            }
            price {
              currency
              inCents
            }
            lineItems {
              name
              price {
                inCents
                currency
              }
              quantity
            }
          }
          ... on MixCartOrder {
            itemTotal {
              currency
              inCents
            }
            itemTotalDiscount {
              currency
              inCents
            }
            mixLineItems: lineItems {
              quantity
              meta {
                product {
                  name
                }
                ... on FlexCartLineItemMeta {
                  price {
                    currency
                    inCents
                  }
                }
              }
            }
            orderMode
            orderNumber
            shippingCost {
              currency
              inCents
            }
            storeCode
            total {
              currency
              inCents
            }
          }
        }
        # Temporary solution to be able to render payments while marketing service is in migration
        # marketingOrderPaymentData {
        #   ... on HintModel {
        #     uuid
        #     bodyTemplate
        #   }
        # }
      }
      ... on SubscriptionPayment {
        id
        state
        invoiceUrl
        price {
          currency
          inCents
        }
        basePrice {
          currency
          inCents
        }
        refundedAmount {
          currency
          inCents
        }
        dueDate {
          ... on Date {
            iso8601
          }
          ... on TimeEstimate {
            atLeast {
              unit
              value
            }
            atMost {
              unit
              value
            }
          }
        }
        paidDate
        contract {
          ... on Flex {
            id
            name
            paymentMethod {
              ... on ByInvoice {
                id
              }
              ... on Card {
                id
              }
              ... on Paypal {
                id
              }
              ... on Sepa {
                id
              }
            }
            goods {
              id
              product {
                id
                category {
                  iconSvgPath
                }
              }
            }
          }
          ... on Mix {
            id
            paymentMethod {
              ... on ByInvoice {
                id
              }
              ... on Card {
                id
              }
              ... on Paypal {
                id
              }
              ... on Sepa {
                id
              }
            }
          }
        }
        subscriptionDetails {
          ... on SubscriptionDetails {
            id
            name
            product {
              id
              category {
                iconSvgPath
              }
            }
          }
        }
        # Temporary solution to be able to render payments while marketing service is in migration
        # marketingSubscriptionPaymentData {
        #   ... on HintModel {
        #     uuid
        #     bodyTemplate
        #   }
        # }
      }
      ... on GroupPayment {
        id
        state
        invoiceUrl
        contracts {
          totalCount
        }
        dueDate {
          ... on Date {
            iso8601
          }
          ... on TimeEstimate {
            atLeast {
              unit
              value
            }
            atMost {
              unit
              value
            }
          }
        }
        paidDate
        price {
          currency
          inCents
        }
        basePrice {
          currency
          inCents
        }
        refundedAmount {
          currency
          inCents
        }
      }
      ... on PurchasePayment {
        id
        state
        datePaid
        invoiceUrl
        dueDate {
          ... on Date {
            iso8601
          }
          ... on TimeEstimate {
            atLeast {
              unit
              value
            }
            atMost {
              unit
              value
            }
          }
        }
        paidDate
        price {
          currency
          inCents
        }
        basePrice {
          currency
          inCents
        }
        refundedAmount {
          currency
          inCents
        }
        contract {
          ... on Flex {
            id
            name
            goods {
              id
              product {
                id
                category {
                  iconSvgPath
                }
              }
            }
          }
          ... on Mix {
            id
          }
        }
        subscriptionDetails {
          ... on SubscriptionDetails {
            id
            name
            product {
              id
              category {
                iconSvgPath
              }
            }
          }
        }
      }
    }
  }
`;

export const PAYMENTS_QUERY = gql`
  ${PAYMENTS_FRAGMENT}

  query Payments(
    $firstPayments: Int!
    $cursor: String
    $subscriptionId: ID
    $where: PaymentsWhereInput
  ) {
    payments(
      first: $firstPayments
      after: $cursor
      subscriptionId: $subscriptionId
      where: $where
    ) {
      ...PaymentsFragment
    }
  }
`;

export const PAYMENTS_DETAILS_QUERY = gql`
  query PaymentsDetails($userId: ID!) {
    user(id: $userId) {
      id
      activeSubscriptionValue {
        currency
        inCents
      }
      monthlyTotal {
        currency
        inCents
      }
      activeSubscriptionValue {
        currency
        inCents
      }
    }
  }
`;

export const SUBSCRIPTION_PAYMENTS_DETAILS_QUERY = gql`
  query SubscriptionPaymentsDetails($id: ID!) {
    subscriptionDetails(id: $id) {
      ... on SubscriptionDetails {
        id
        name
        state
        note {
          ... on NextPaymentNote {
            date
            paymentMethod {
              __typename
            }
          }
          ... on TextNoteInterface {
            text
          }
        }
        variant {
          id
          colorCodes
          colorTitle
          imageUrl
        }
        price {
          currency
          inCents
        }
      }
      ... on Flex {
        id
        contractName: name
        contractState: state
        note {
          ... on NextPaymentNote {
            date
            paymentMethod {
              __typename
            }
          }
          ... on TextNoteInterface {
            text
          }
        }
        goods {
          id
          variant {
            id
            colorCodes
            colorTitle
            imageUrl
          }
        }
        billingTerms {
          price {
            currency
            inCents
          }
        }
      }
    }
  }
`;
