export const tk = {
  // titles
  documentTitleYourPaymentsPage: 'documentTitle.yourPaymentsPage',
  documentTitlePaymentMethodListPage: 'documentTitle.paymentMethodListPage',
  documentTitlePaymentMethodDetailsPage: 'documentTitle.paymentMethodDetailsPage',
  documentTitleAddPaymentMethodPage: 'documentTitle.addPaymentMethodPage',
  documentTitleChangePaymentMethodPage: 'documentTitle.changePaymentMethodPage',
  documentTitleBulkPaymentMethodPage: 'documentTitle.bulkPaymentMethodPage',
  // Error pages
  pageNotFoundStatusText: 'errorNotFound.statusText',
  pageNotFoundTitle: 'errorNotFound.title',
  pageNotFoundText: 'errorNotFound.text',
  pageNotFoundButtonText: 'errorNotFound.buttonText',
  pageErrorStatusText: 'error500.statusText',
  pageErrorTitle: 'error500.title',
  pageErrorText: 'error500.text',
  pageErrorButtonText: 'error500.buttonText',

  // New Error pages
  errorScreenTitle: 'errorScreenTitle',
  errorScreenText: 'errorScreenText',
  errorScreenButtonText: 'errorScreenButtonText',

  // your-payments list
  yourPaymentsPageTitle: 'yourPayments.header.title',
  yourPaymentsPageMainTitle: 'yourPayments.main.title',
  yourPaymentsPageTotalTitle: 'yourPayments.currentTotal',
  yourPaymentsPageMonthlyTotalTitle: 'yourPayments.monthlyTotal',
  yourPaymentsPageTotalPerSubscriptionTitle: 'yourPayments.subscriptionTotal',
  yourPaymentsPageDurationText: 'yourPayments.perMonth',
  yourPaymentsPageForYourTech: 'yourPayments.forYourTech',
  yourPaymentsListTitlePayments: 'yourPayments.list.payments',
  yourPaymentsListTitleStatus: 'yourPayments.list.status',
  yourPaymentsListTitleAmount: 'yourPayments.list.amount',
  yourPaymentsListTitleInvoice: 'yourPayments.list.invoice',
  yourPaymentsFilterMainTitle: 'yourPayments.filter.main.title',
  yourPaymentsFilterChipTitle: 'yourPayments.filter.chip.title',
  yourPaymentsFilterTitleDevice: 'yourPayments.filter.device',
  yourPaymentsFilterTitlePaymentType: 'yourPayments.filter.payment.type',
  yourPaymentsClearAllButtonText: 'yourPayments.clearAllButton',
  yourPaymentsShowAllButtonText: 'yourPayments.showAllButton',
  yourPaymentsTypeOrderText: 'yourPayments.type.order',
  yourPaymentsTypeSubscriptionText: 'yourPayments.type.subscription',
  yourPaymentsTypePurchaseText: 'yourPayments.type.purchase',
  yourPaymentsTypeGroupText: 'yourPayments.type.group',
  yourPaymentsPageAwaitingGroupSubtitle: 'yourPayments.subscriptionList.awaitingPayment',
  yourPaymentsPageComingUpGroupSubtitle: 'yourPayments.subscriptionList.comingUp',
  yourPaymentsPageNextPaymentNoteText: 'yourTech.noteNextPayment',
  yourPaymentsLinkToSubscriptionDescription: 'yourTech.paymentSheet.subscription',
  yourPaymentsLinkToSubscriptionText: 'yourTech.paymentSheet.viewSubscriptions',
  yourPaymentsLinkToPaymentMethodsText: 'yourPayments.paymentMethodsLinkText',
  yourPaymentsLinkToMixSubscriptionText: 'yourTech.paymentSheet.manageYourMix',
  yourPaymentsLinkToMixSubscriptionDescription: 'yourTech.paymentSheet.openOnYourTech',
  yourPaymentsOrderTimeEstimationYear: 'yourTech.year',
  yourPaymentsOrderTimeEstimationMonth: 'yourTech.month',
  yourPaymentsOrderTimeEstimationDay: 'yourTech.day',
  yourPaymentsOrderTimeEstimationHour: 'yourTech.hour',
  yourPaymentsOrderTimeEstimationMinute: 'yourTech.minute',
  yourPaymentsStateDue: 'yourPayments.state.due',
  yourPaymentsStateFailed: 'yourPayments.state.failed',
  yourPaymentsStatePaid: 'yourPayments.state.paid',
  yourPaymentsStateScheduled: 'yourPayments.state.scheduled',
  yourPaymentsStatePurchased: 'yourPayments.state.purchased',
  yourPaymentsStateCanceled: 'yourPayments.state.canceled',
  yourPaymentsNoticeDiscountApplied: 'yourPayments.notice.discountApplied',
  yourPaymentsNoticeFailedOrderPayment: 'yourPayments.notice.failedOrderPayment',
  yourPaymentsRefundedTitle: 'yourPayments.refunded.title',
  yourPaymentsInAbout: 'yourTech.inAbout',
  yourPaymentsOrderPaymentTitle: 'yourPayments.paymentListing.order',
  yourPaymentsGroupPaymentTitle: 'yourPayments.groupPayment.name',
  yourPaymentsMixPaymentTitle: 'yourPayments.mixContract.name',
  yourPaymentsEmptyStateTitle: 'yourPayments.emptyStateTitleLookingAt',
  yourPaymentsEmptyStateText: 'yourPayments.emptyStateSubtitleDetails',
  yourPaymentsNoResultTitle: 'yourPayments.noResult.title',
  yourPaymentsFilterNoResultText: 'yourPayments.filter.noResult.subtitle',
  yourPaymentsClearFiltersButtonText: 'yourPayments.clearFiltersButton',
  yourPaymentsDownloadInvoiceButtonText: 'yourTech.paymentSheet.downloadInvoice',
  yourPaymentsDownloadInvoiceButtonDescription: 'yourTech.paymentSheet.availableOncePaid',
  yourPaymentsDownloadInvoiceButtonDisabledFullText:
    'yourTech.paymentSheet.notAvailableYet.learnMore',
  yourPaymentsDownloadInvoiceButtonDisabledSimpleText: 'yourTech.paymentSheet.notAvailableYet',
  yourPaymentsDownloadInvoiceButtonDisabledLearnMore: 'yourTech.paymentSheet.notAvailableLearnMore',
  yourPaymentsFailedBannerTitle: 'yourPayments.failedBanner.title',
  yourPaymentsFailedBannerSubtitle: 'yourPayments.failedBanner.subtitle',
  yourPaymentsFailedBannerYourRentals: 'yourPayments.failedBanner.your.rentals',
  yourPaymentsFailedBannerRetryLearnMore: 'yourPayments.failedBanner.retry.learnMore',
  yourPaymentsViewDetailsButtonMobileText: 'yourPayments.viewDetailsButton.mobile',
  yourPaymentsViewDetailsButtonText: 'yourPayments.viewDetailsButton',

  // payment-methods management
  paymentMethodListPageTitle: 'payments.paymentMethodsListPage.title',
  paymentMethodListPageDescription: 'payments.paymentMethodsListPage.description',
  paymentMethodListPageAddButtonText: 'payments.paymentMethodsListPage.addBtnText',
  paymentMethodListPageCardsSubtitle: 'yourProfile.paymentMethods.list.subtitleCards',
  paymentMethodListPageOthersSubtitle: 'yourProfile.paymentMethods.list.subtitleOthers',
  paymentMethodDateAddedText: 'yourProfile.paymentMethods.item.dateAddedText',
  paymentMethodExpiringText: 'yourProfile.paymentMethods.item.expiringText',
  paymentMethodExpiredText: 'yourProfile.paymentMethods.item.expiredText',
  paymentMethodNoSubscriptionsText: 'yourProfile.paymentMethods.item.noSubscriptionsText',
  paymentMethodNumberSubscriptionsText: 'yourProfile.paymentMethods.item.numberSubscriptionsText',
  paymentMethodBulkTitle: 'yourProfile.paymentMethods.changePaymentMethod.listing.title',
  paymentMethodBulkSubtitle: 'yourProfile.paymentMethods.bulk.subtitle',
  paymentMethodBulkTwoHoursNoticeText: 'yourProfile.paymentMethods.bulk.twoHoursNoticeText',
  paymentMethodBulkCancelButtonText: 'yourProfile.paymentMethods.bulk.cancelButtonText',
  paymentMethodBulkUpdateButtonText: 'yourProfile.paymentMethods.bulk.updateButtonText',
  paymentMethodBulkSuccessToastButtonText: 'yourProfile.paymentMethods.bulk.successToastButtonText',
  paymentMethodBulkSuccessToastTitle: 'yourProfile.paymentMethods.bulk.successToastTitle',
  paymentMethodInBulkUpdateText: 'yourProfile.paymentMethods.item.inBulkUpdateText',
  paymentMethodInBulkWarningPrefix: 'yourProfile.paymentMethods.item.inBulkUpdateWarningPrefix',
  paymentMethodInBulkDeleteWarningPrefix:
    'yourProfile.paymentMethods.item.inBulkUpdateDeleteWarningPrefix',
  paymentMethodInBulkWarning2Hours: 'yourProfile.paymentMethods.item.inBulkUpdateWarning2Hours',
  bulkUpdateChangePaymentMethodButtonText: 'bulkUpdate.changePaymentMethodButtonText',
  bulkUpdateSuccessToastText: 'bulkUpdate.successToastText',
  bulkUpdateDeleteModeTitle: 'bulkUpdate.deleteModeTitle',
  bulkUpdateDeleteModeSubtitle: 'bulkUpdate.deleteModeSubtitle',
  bulkUpdateDeleteModeSubmitButtonText: 'bulkUpdate.deleteModeSubmitButtonText',
  bulkUpdateDeleteConfirmationDialogText: 'bulkUpdate.confirmationDialogText',
  bulkUpdateBeingDeletedWarning: 'bulkUpdate.beingDeletedWarning',
  bulkUpdateErrorToastTitle: 'bulkUpdate.errorToastTitle',
  bulkUpdateErrorToastTextPrefix: 'bulkUpdate.errorToastTextPrefix',
  bulkUpdateErrorToastButtonText: 'bulkUpdate.errorToastButtonText',
  yourProfileLinkText: 'yourProfile.title',
  paymentMethodListLinkText: 'payments.paymentMethodsListPage.title',
  helpLinkText: 'pmm.helpLinkText', // TODO translate this phrase
  paymentMethodProvidersTitle: 'yourProfile.paymentMethods.addPaymentMethod.providers.title',
  paymentMethodProvidersSubtitle: 'yourProfile.paymentMethods.addPaymentMethod.providers.subtitle',
  paymentMethodCardTitle: 'payments.paymentMethodGatewayType.cardText',
  paymentMethodPaypalTitle: 'payments.paymentMethodGatewayType.paypalText',
  paymentMethodSepaTitle: 'payments.paymentMethodGatewayType.sepaText',
  paymentMethodSepaNoticeText: 'payments.paymentMethod.sepa.noticeText',
  paymentMethodPayByInvoiceBrand: 'pmm.payByInvoiceBrand',
  paymentMethodInvalidNote: 'pmm.invalidPmNote',
  paymentMethodInvalidNoticeText: 'pmm.invalidPmNoticeText',
  paymentMethodDeletionLockReasonText: 'pmm.deletionLockReasonText',
  paymentMethodNotFoundButtonText: 'pmm.paymentMethodNotFoundButtonText',
  emptyCardHolderName: 'pmm.cardHolderName',
  emptyCardExpiryDate: 'pmm.expiryDate',
  paymentMethodPopupBackButtonText:
    'yourProfile.paymentMethods.delete.confirmationDialogCancelButtonText',
  cardNumberLabel: 'yourProfile.paymentMethods.addPaymentMethod.creditCard.form.cardNumber',
  cardHolderLabel: 'yourProfile.paymentMethods.addPaymentMethod.creditCard.form.cardHolderName',
  expiryDateLabel: 'yourProfile.paymentMethods.addPaymentMethod.creditCard.form.expiryDate',
  cvvLabel: 'yourProfile.paymentMethods.addPaymentMethod.creditCard.form.cvv',
  addPaymentMethodSubmitButtonText:
    'yourProfile.paymentMethods.addPaymentMethod.creditCard.form.submitBtnText',
  cardHolderRequiredError:
    'yourProfile.paymentMethods.addPaymentMethod.creditCard.errors.cardHolderNameRequired',
  cardNumberRequiredError:
    'yourProfile.paymentMethods.addPaymentMethod.creditCard.errors.cardNumberRequired',
  cardCvvRequiredError:
    'yourProfile.paymentMethods.addPaymentMethod.creditCard.errors.cardCVVRequired',
  cardExpirationRequiredError:
    'yourProfile.paymentMethods.addPaymentMethod.creditCard.errors.cardExpirationRequired',
  addPaymentMethodErrorTitle: 'yourProfile.paymentMethods.addPaymentMethod.error.title',
  addPaymentMethodErrorInfo1: 'yourProfile.paymentMethods.addPaymentMethod.error.info1',
  addPaymentMethodErrorInfo2: 'yourProfile.paymentMethods.addPaymentMethod.error.info2',
  addPaymentMethodErrorGenericTitle: 'pmm.errorScreenGenericTitle',
  addPaymentMethodErrorGenericText: 'pmm.errorScreenGenericText',
  addPaymentMethodErrorFraudulentTitle: 'pmm.errorScreenFraudulentTitle',
  addPaymentMethodErrorFraudulentText: 'pmm.errorScreenFraudulentText',
  addPaymentMethodError3DSVerificationTitle: 'pmm.errorScreen3DSVerificationTitle',
  addPaymentMethodError3DSVerificationText: 'pmm.errorScreen3DSVerificationText',
  addPaymentMethodErrorBankDeclinedTransactionTitle: 'pmm.errorScreenBankDeclinedTransactionTitle',
  addPaymentMethodErrorBankDeclinedTransactionText: 'pmm.errorScreenBankDeclinedTransactionText',
  addPaymentMethodErrorBlockedByRiskTitle: 'pmm.errorScreenBlockedByRiskTitle',
  addPaymentMethodErrorBlockedByRiskText: 'pmm.errorScreenBlockedByRiskText',
  addPaymentMethodErrorCardExpiredTitle: 'pmm.errorScreenCardExpiredTitle',
  addPaymentMethodErrorCardExpiredText: 'pmm.errorScreenCardExpiredText',
  addPaymentMethodErrorLostOrStolenTitle: 'pmm.errorScreenCardLostOrStolenTitle',
  addPaymentMethodErrorLostOrStolenText: 'pmm.errorScreenCardLostOrStolenText',
  addCardSubmitButtonText: 'pmm.addPaymentMethod.cardSubmitButtonText',
  optionsScreenTitle: 'pmm.optionsScreen.title',
  optionsScreenSeparatorText: 'pmm.optionsScreen.separatorText',
  addPaymentMethodCardNumberLabel: 'pmm.addPaymentMethod.cardNumberLabel',
  addPaymentMethodCardholderNameLabel: 'pmm.addPaymentMethod.cardholderNameLabel',
  addPaymentMethodCardExpiryDateLabel: 'pmm.addPaymentMethod.cardExpiryDateLabel',
  addPaymentMethodCardCvvLabel: 'pmm.addPaymentMethod.cardCvvLabel',
  addPaymentMethodCardNumberInvalidText: 'pmm.addPaymentMethod.cardNumberInvalidText',
  addPaymentMethodCardholderNameInvalidText: 'pmm.addPaymentMethod.cardholderNameInvalidText',
  addPaymentMethodCardExpiryDateInvalidText: 'pmm.addPaymentMethod.cardExpiryDateInvalidText',
  addPaymentMethodCardCvvInvalidText: 'pmm.addPaymentMethod.cardCvvInvalidText',
  addPaymentMethodErrorButtonText:
    'yourProfile.paymentMethods.addPaymentMethod.error.action_button_text',
  addPaymentMethodSuccessTitle: 'yourProfile.paymentMethods.addPaymentMethod.success.title',
  addPaymentMethodSuccessText: 'yourProfile.paymentMethods.addPaymentMethod.success.subtitle',
  addPaymentMethodSuccessButtonText:
    'yourProfile.paymentMethods.addPaymentMethod.success.action_button',
  deletePaymentMethodSuccessToastTitle: 'yourProfile.paymentMethods.delete.deleteSuccessToastTitle',
  deletePaymentMethodSuccessToastGenericText:
    'yourProfile.paymentMethods.delete.deleteSuccessGenericToastText',
  deletePaymentMethodFailureToastTitle: 'yourProfile.paymentMethods.delete.deleteFailureToastTitle',
  deletePaymentMethodFailureToastText: 'yourProfile.paymentMethods.delete.deleteFailureToastText',
  deletePaymentMethodWarningText: 'deletePaymentMethod.businessWarningText',
  deletePaymentMethodSupportLinkText: 'deletePaymentMethod.supportLinkText',
  deletePaymentMethodConfirmationDialogText:
    'yourProfile.paymentMethods.delete.confirmationDialogText',
  deletePaymentMethodButtonText: 'yourProfile.paymentMethods.delete.deletePaymentMethodButtonText',
  deletePaymentMethodConfirmationDialogTitle:
    'yourProfile.paymentMethods.delete.confirmationDialogTitle',
  deletePaymentMethodConfirmationDialogCancelButtonText:
    'yourProfile.paymentMethods.delete.confirmationDialogCancelButtonText',
  deletePaymentMethodConfirmationDialogConfirmButtonText:
    'yourProfile.paymentMethods.delete.confirmationDialogConfirmButtonText',
  deletePaymentMethodConfirmationDialogLoadingText:
    'yourProfile.paymentMethods.delete.confirmationDialogLoadingText',
  deletePaymentMethodLinkedSubscriptionsTitle:
    'yourProfile.paymentMethods.delete.linkedSubscriptionsTitle',
  deletePaymentMethodLinkedSubscriptionsEmptyText:
    'yourProfile.paymentMethods.delete.linkedSubscriptionsEmptyText',
  deletePaymentMethodLinkedSubscriptionsWarningText:
    'yourProfile.paymentMethods.delete.warningBar.text',
  paypalRedirectionText: 'yourProfile.paymentMethods.addPaymentMethod.paypalRedirection.text',
  sepaRedirectionText: 'yourProfile.paymentMethods.addPaymentMethod.sepaRedirection.text',
  commonLoadingText: 'yourProfile.paymentMethods.delete.confirmationDialogLoadingText',
  nextPaymentDueText: 'yourPayments.state.due',
  changePaymentMethodSuccessTitle: 'yourProfile.paymentMethods.changePaymentMethod.success.title',
  changePaymentMethodSuccessText: 'yourProfile.paymentMethods.changePaymentMethod.success.subtitle',
  changePaymentMethodSuccessSubscriptionTitle:
    'yourProfile.paymentMethods.changePaymentMethod.success.liked_subscription_text',
  changePaymentMethodSuccessButtonText:
    'yourProfile.paymentMethods.changePaymentMethod.success.action_button',
  changePaymentMethodAddButtonText:
    'yourProfile.paymentMethods.changePaymentMethod.listing.addPaymentMethodButton',
  creditCardFormNoticeTextEu: 'paymentMethods.creditCardForm.noticeTextEu',
  creditCardFormNoticeTextUs: 'paymentMethods.creditCardForm.noticeTextUs',
  changePaymentMethodRetryNoticeText:
    'yourProfile.paymentMethods.changePaymentMethod.retry.noticeText',
  changePaymentMethodBulkRetryNoticeText:
    'yourProfile.paymentMethods.changePaymentMethod.bulk.retry.noticeText',
  changePaymentMethodSuccessRetryText: 'yourProfile.paymentMethods.changePaymentMethod.success.retry.subtitle',
};
