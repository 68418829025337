import styled from 'styled-components';
import { DefaultButton } from '@getgrover/ui';

export const Root = styled.div<{ isMobile: boolean, isLoading?: boolean }>`
  margin: ${({ isMobile }) => isMobile ? '-8px 0 16px' : '24px 0 16px'};
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme, isLoading }) => isLoading ? theme.colors.background.superLightGrey : theme.colors.background.lightRed};
`;

export const ViewDetailsButton = styled(DefaultButton)`
  background-color: ${({ theme }) => theme.colors.background.white};
  color: ${({ theme }) => theme.colors.text.darkerGrey};

  :hover {
    background-color: ${({ theme }) => theme.colors.hover.lighterGrey};
  }
`;