import { gql } from '@apollo/client';

export const PAYMENT_METHOD_QUERY = gql`
  query PaymentMethod($id: ID!) {
    paymentMethod(id: $id) {
      id
      groverApiId
      status
      __typename
    }
  }
`;