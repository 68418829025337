import React from 'react';
import { useTheme } from 'styled-components';
import { Button } from '@getgrover/ui';

import { useScreenMatch } from '@/hooks/useScreenMatch';
import { Wrapper } from '@/components/Wrapper';
import { Group } from '@/components/Group';
import { Text } from '@/components/Text';

interface EmptyScreenProps {
  title?: string;
  subtitle?: string;
  onClick?: () => void;
  buttonText?: string;
}

export const EmptyScreen = ({ title, subtitle, onClick, buttonText }: EmptyScreenProps) => {
  const theme = useTheme();
  const { isMobile } = useScreenMatch();

  return (
    <Wrapper
      maxWidth={isMobile ? '380px' : 'unset'}
      padding={`${isMobile ? '80px' : '60px'} 16px 16px 16px`}
      margin="0 auto"
      justifyContent="center"
    >
      <Group vertical centered gap={8} mobileGap={8}>
        <Text centered typography="Subheadline" color={theme.colors.text.darkerGrey}>
          {title}
        </Text>
        <Text centered typography="Paragraph" color={theme.colors.text.baseGrey}>
          {subtitle}
        </Text>
        {buttonText ? (
          <Button small centred style={{ padding: '4px 12px' }} onClick={onClick}>
            <Text bold typography="Paragraph">
              {buttonText}
            </Text>
          </Button>
        ) : null}
      </Group>
    </Wrapper>
  );
};
